<template>
  <div class="notify-item" @click="$emit('open-modal')">
    <div class="notify-item__icon">
      <div v-if="item.icon" class="notify-item__icon-wrap" v-html="item.icon"></div>
      <div v-else class="notify-item__icon-wrap notify-item__no-icon">
        <RemixIconComponent category="system" name="checkbox-circle-fill" />
      </div>
    </div>
    <div class="notify-item__info">
      <div class="notify-item__info-title">{{ item.title }}</div>
      <div>{{ item.subtitle }}</div>
    </div>
    <div class="notify-item__date">{{ item.send_at | formatDate }}</div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  emits: ["open-modal"],
  name: "notify",
  components: { RemixIconComponent, IconComponent },
  props: {
    item: Object,
  },
  data() {
    return {};
  },
};
</script>

<style lang="stylus">
.notify-item {
  cursor: pointer
  border 1px solid var(--border-color-2)
  gap 8px
  padding 8px
  border-radius 16px
  height: 66px;
  display: grid;
  grid-template-columns: 48px 1fr 64px;

  &__icon {
    width 48px
    height: 48px;
    border-radius 8px
    overflow hidden
    background var(--green-50)
    display flex
    justify-content center
    align-items center
  }
  &__no-icon {

  }

  &__info {
    display flex
    flex-direction column
    gap 4px
    font-weight 400
    font-size 0.725rem
    line-height 14px
    justify-content center
    color var(--gray-5)

    &-title {
      font-weight 600
      font-size 1rem
      line-height 22px
    }
  }

  &__date {
    font-size 0.725rem
    line-height 14px
    justify-content center
    color var(--gray-5)
    align-self flex-end
  }

  &__icon-wrap {
    width 16px
    height 16px

    figure {
      display: flex
    }

    .icon svg path {
      fill var(--green)
    }
  }
}
</style>
